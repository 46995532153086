import "./Button.scss";
import { Link } from "react-router-dom";

const Button = ({ 
    type = "primary", 
    isLink, 
    text, 
    buttonSize, 
    buttonWidth, 
    to, 
    onClick, 
    target, 
    rel 
}) => {
    const buttonClass = `button button--${type} button__${buttonSize} button__${buttonWidth}`;
    const textClass = `button__text button__${buttonWidth}`;

    return isLink ? (
        <Link className={buttonClass} target={target} rel={rel} to={to}>
            <p className={textClass}>{text}</p>
        </Link>
    ) : (
        <button className={buttonClass} onClick={onClick}>
            <p className={textClass}>{text}</p>
        </button>
    );
};

export default Button;