import "./CardsComposition.scss";
import Card from "../../components/Card/Card";

function CardsComposition({cardData, size}){
    return(
        <section className="cards-composition">
            <div className="cards-composition__section-wrapper">
                {cardData.map((card) =>(
                    <Card 
                        icon={card.icon}
                        title={card.title}
                        body={card.body}
                        isIcon={!!card.icon}
                        isTitle={!!card.title}
                        size={card.size}
                    />
                ))}
            </div>
        </section>
    );
}

export default CardsComposition;