import "./GameList.scss"

function GameList({image, alt, gameName}){
    return(
        <div className="game-list">
            <img className="game-list__image" src={image} alt={alt}/>
            <p className="game-list__title" >{gameName}</p>
        </div>
    );
}

export default GameList;