import "./TabletList.scss";

function TabletList({title, body}){
    return(
        <div className="table-list">
            <h4>{title}</h4>
            <p className="table-list__text">{body}</p>
        </div>
    );
};

export default TabletList;