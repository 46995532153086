import React from "react";
import './WorkGallery.scss';
import Header from "../../components/Header/Header";

function WorkGallery(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
            </main>
        </div>
    );
};

export default WorkGallery;