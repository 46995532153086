import React from "react";
import './FullWidthImageSection.scss';

function FullWidthImageSection({header, body, image}){
    return(
        <section className="full-width-image-section">
            <div className="full-width-image-section__section-wrapper">
                <div className="full-width-image-section__text-wrapper">
                    <h2 className="full-width-image-section__text-wrapper--header">{header}</h2>
                    <p className="full-width-image-section__text-wrapper--body">{body}</p>
                </div>

                <div className="full-width-image-section__image-wrapper">
                    <img className="full-width-image-section__image-wrapper--image" src={image} alt="Xiaoho's images" />
                </div>
            </div>
        </section>
    );
}

export default FullWidthImageSection;