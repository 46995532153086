import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe/AboutMe';
import WorkGallery from './pages/WorkGallery/WorkGallery';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about_me" element={<AboutMe />} />
      <Route path="/work_gallery" element={<WorkGallery />} />
    </Routes>
  );
}

export default App;