import React, { useState } from "react";
import "./Hero.scss";

function Hero({ header, body, isStack, image, alt, secondImage, allowClick }) {
    const [currentImage, setCurrentImage] = useState(image);
    const [isFading, setIsFading] = useState(false);

    const handleImageClick = () => {
        if (!allowClick) return;

        setIsFading(true);
        setTimeout(() => {
            setCurrentImage(currentImage === image ? secondImage : image);
            setIsFading(false);
        }, 300);
    };

    return (
        <section className={`hero ${isStack ? "hero--stack" : ""}`}>
            <div className={`hero__section-container ${isStack ? "hero__section-container--stack" : ""}`}>
                <div className={`hero__text-wrapper ${isStack ? "hero__text-wrapper--stack" : ""}`}>
                    <h1 className={`hero__text-wrapper-header ${isStack ? "hero__text-wrapper-header--stack" : ""}`}>
                        {header}
                    </h1>
                    <p className={`hero__text-wrapper-body ${isStack ? "hero__text-wrapper-body--stack" : ""}`}>
                        {body}
                    </p>
                </div>

                <img
                    className={`hero__image ${isStack ? "hero__image--stack" : ""} ${isFading ? "fade" : ""}`}
                    src={currentImage}
                    alt={alt}
                    onClick={handleImageClick}
                />
            </div>
        </section>
    );
}

export default Hero;