import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import './TextLink.scss';

function TextLink({ text, url = "", isActive }) {
    const location = useLocation();  
    const isExternal = url.startsWith("http");  
    const activeClass = isActive || location.pathname === url ? "active" : "";

    if (isExternal) {
        return (
            <a 
                className={`text-link ${activeClass}`} 
                href={url}
                target="_blank" 
                rel="noopener noreferrer"
            >
                <div className="text-link__inner">{text}</div>
            </a>
        );
    }

    return (
        <NavLink 
            to={url} 
            className={({ isActive }) => `text-link ${isActive ? "active" : ""}`}
        >
            <div className={`text-link__inner ${location.pathname === url ? "active" : ""}`}>{text}</div>
        </NavLink>
    );
}

export default TextLink;

