import React from "react";
import './IconLink.scss';

function IconLink({ icon, isLink, onClick, url, tooltipText, isTooltip }) {
    return isLink ? (
        <a className="icon-link" href={url} target="_blank" rel="noopener noreferrer">
            <div className="icon-link__wrapper">
                <i className={icon}></i>
                {isTooltip &&
                  <span className="icon-link__tooltip">{tooltipText}</span>
                }
            </div>
        </a>
    ) : (
        <button className="icon-link" onClick={onClick}>
            <div className="icon-link__wrapper">
                <i className={icon}></i>
                {isTooltip &&
                  <span className="icon-link__tooltip">{tooltipText}</span>
                }
            </div>
        </button>
    );
}

export default IconLink;