import React from "react";
import './TabletListSection.scss';
import TabletList from "../../components/TabletList/TabletList";

function TabletListSection({header, body, datas}){
    return(
        <section className="table-list-section">
            <div className="table-list-section__section-wrapper">
                <div className="table-list-section__table-wrapper">
                    <h2 className="table-list-section__table-wrapper--header">{header}</h2>
                    <p className="table-list-section__table-wrapper--body">{body}</p>
                </div>

                {datas.map((data) =>(
                    <TabletList
                    title={data.title}
                    body={data.body}
                    />
                ))}
            </div>
        </section>
    );
}

export default TabletListSection;