import "./Card.scss";

function Card({icon, title, body, isIcon, isTitle, size}){
    return(
        <div className={`card card--${size}`}>
            {isIcon && 
                <i className={`card__icon fa-regular fa-duotone fa-lg ${icon}`}></i>
            }
            {isTitle && 
                <h3 className="card__title">{title}</h3>
            }
            <p className="card__text">{body}</p>
        </div>
    );
}

export default Card;