import FaqItem from '../../components/FaqItem/FaqItem';
import { useState } from 'react';
import './Faq.scss'

function Faq({header, body, faqData}){
    const [faqOpenId, isFaqOpenId] = useState(null);

    const handleFaqClick = (id) =>{
        isFaqOpenId(prevId =>(prevId === id ? null:id));
    }

    return(
        <section className="faq">
            <div className="faq__text-wrapper">
                <h2 className="faq__text-wrapper--header">{header}</h2>
                <p className="faq__text-wrapper--body">{body}</p>
            </div>

            <div className="faq__content-wrapper">
                {faqData.map((item) =>(
                <FaqItem 
                    key={item.id}
                    id={item.id}
                    header={item.header}
                    body={item.body}
                    isOpen={faqOpenId === item.id}
                    onClick={handleFaqClick}
                />
                ))}
           </div>
        </section>
    );
}

export default Faq;