import { useEffect, useState } from "react";
import "./Ticker.scss";
import HelcimLogo from "../../assets/images/helcim.png";
import FlashfoodLogo from "../../assets/images/flashfood.png";
import CapitalOneLogo from "../../assets/images/capital-one.png";
import VoyagerLogo from "../../assets/images/voyager.png";

function Ticker({ header, body, isBody }) {
    const logos = [
        { src: HelcimLogo, alt: "Helcim's logo" },
        { src: FlashfoodLogo, alt: "Flashfood's logo" },
        { src: CapitalOneLogo, alt: "Capital One's logo" },
        { src: VoyagerLogo, alt: "Voyager's logo" }
    ];

    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    useEffect(() => {
        const checkScreenSize = () => setIsMobile(window.innerWidth < 640);
        window.addEventListener("resize", checkScreenSize);
        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    return (
        <section className="ticker">
            <div className="ticker__text-wrapper">
                <h2 className="ticker__text-wrapper--header">{header}</h2>
                {isBody && <p className="ticker__text-wrapper--body">{body}</p>}
            </div>

            <div className="ticker__image-container">
                <div className={`ticker__image-wrapper ${isMobile ? "ticker__image-wrapper--animated" : ""}`}>
                    {isMobile
                        ? [...logos, ...logos].map((logo, index) => (
                              <img key={index} className="ticker__image" src={logo.src} alt={logo.alt} />
                          ))
                        : logos.map((logo, index) => (
                              <img key={index} className="ticker__image" src={logo.src} alt={logo.alt} />
                          ))}
                </div>
            </div>
        </section>
    );
}

export default Ticker;