import "./FaqItem.scss"

function FaqItem({header, body, onClick, isOpen, id}){

    return(
        <div className="faq-item">
            <button className="faq-item__button" onClick={()=> onClick(id)}>
                <h4>{header}</h4>
                <i className={`fa-solid fa-lg ${isOpen ? "fa-minus" : "fa-plus"}`}></i>
            </button>
            {isOpen && <p className="faq-item__text">{body}</p>}
        </div>
    );
}

export default FaqItem;
