import "./BannerPromo.scss";
import Button from "../Button/Button";

function BannerPromo({ 
    header, 
    body, 
    image, 
    alt, 
    isButton,  
    type, 
    isLink, 
    text, 
    buttonSize, 
    buttonWidth, 
    to, 
    onClick, 
    target, 
    rel 
}) {
    return (
        <div className="banner-promo">
            <div className="banner-promo__section-wrapper">
                <div className="banner-promo__banner-wrapper">
                    <div className="banner-promo__left-wrapper">
                        <div className="banner-promo__text-wrapper">
                            <h3 className="banner-promo__header">{header}</h3>
                            <p className="banner-promo__body">{body}</p>
                        </div>

                        {isButton && (
                            <Button 
                                type={type} 
                                isLink={isLink} 
                                text={text} 
                                buttonSize={buttonSize} 
                                buttonWidth={buttonWidth} 
                                to={to} 
                                onClick={onClick} 
                                target={target} 
                                rel={rel}
                            />
                        )}
                    </div>

                    <div className="banner-promo__image-wrapper">
                        <img className="banner-promo__image" src={image} alt={alt}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerPromo;