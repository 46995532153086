import PanelDisplay from "../../components/PanelDisplay/PanelDisplay";
import "./PanelSection.scss";

function PanelSection({ panels }) {
    return (
        <section className="panel-section">
            <div className="panel-section__section-wrapper">
                {panels.map((panel) => (
                    <PanelDisplay
                        key={panel.id}
                        isReverse={panel.reverse === "false"}
                        header={panel.header}
                        body={panel.body}
                        image={panel.image}
                        alt={panel.alt}
                        allowClick={panel.allowClick} 
                        secondImage={panel.secondImage} 
                    />
                ))}
            </div>
        </section>
    );
}

export default PanelSection;