import React, { useState, useEffect } from "react";
import './Footer.scss';

function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());
    const [time, setTime] = useState("");
    const [emoji, setEmoji] = useState("");

    function formatTime(date) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    }

    function getEmoji(date) {
        const hours = date.getHours();
        return (hours >= 6 && hours < 18) ? "🏙" : "🌃";  
    }

    useEffect(() => {
        setTime(formatTime(new Date()));   
        setEmoji(getEmoji(new Date()));   

        const yearInterval = setInterval(() => {
            setYear(new Date().getFullYear());
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(yearInterval);
    }, []);

    useEffect(() => {
        const timeInterval = setInterval(() => {
            const now = new Date();
            setTime(formatTime(now));
            setEmoji(getEmoji(now));
        }, 1000);  

        return () => clearInterval(timeInterval);
    }, []);

    return (
        <footer className="footer">
            <p className="footer__text">© {year} - Sony Chen {emoji} Toronto {time}</p>
        </footer>
    );
}

export default Footer;